import uuid from 'react-uuid';
import CheckAuth from '../components/Auth/Auth.service';

import { Config as cf } from '../config';

import { getCookie } from './CookieService.js';

class JsonRPC {
    id = 0;
    URL = cf.API_URL;
    jsonrpc = cf.jsonrpc;
    headers = {'Content-Type': 'application/json'};
    token = decodeURI(getCookie('token'));

    constructor({method, params}) {
        this.id = uuid();
        this.method = method;
        this.params = params;
    }

    async request(method, history, isAuth) {
        try {
            const response = await fetch(this.URL + 'api', {
                method,
                mode: 'cors',
                credentials: 'same-origin',
                headers: this.headers,
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({
                    id: this.id,
                    jsonrpc: this.jsonrpc,
                    method: this.method,
                    params: this.params,
                })
            });
            let res;
            if (!isAuth && response.status === 401) {
                CheckAuth.removeAuthentificationData();
                history.push('/auth?reauth=true');
                res = { 
                    errorCode: response.status,
                    errorDescription: response.statusText
                };
            } else {
                switch (response.status) {
                    case 403:
                        res = {
                            errorCode: response.status,
                            errorDescription: response.statusText + ' - не хватает прав или у вас не доступа к ресурсу.',
                        };
                        break;
                    case 400:
                        if (response.json) {
                            res = (await response.json()).result;
                        } else {
                            res = {
                                errorCode: response.status,
                                errorDescription: response.statusText,
                                requestUrl: response.url
                            }
                        }
                        break;
                    case 500:
                        res = {
                            errorCode: response.status,
                            errorDescription: response.statusText,
                            requestUrl: response.url
                        };
                        break;
                    default:
                        res = (await response.json()).result;
                        break;
                } 
            }
            return res;
        } catch (error) {
            return {
                errorCode: 'Error',
                errorDescription: error.message,
                requestUrl: cf.API_URL.url + 'api'
            };
        }
    }
}

const JsonRpc = async (params, history, auth = false) => {
    const jsonrpc = new JsonRPC(params);

    if (jsonrpc.token !== 'undefined' && !auth) {
        jsonrpc.headers['x-access-token'] = jsonrpc.token;
    }

    const responce = await jsonrpc.request('POST', history, auth);
    return responce;
}

export default JsonRpc;
