import React from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArchiveIcon from '@material-ui/icons/Archive';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import HomeIcon from '@material-ui/icons/Home';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TableChartIcon from '@material-ui/icons/TableChart';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import EditIcon from '@material-ui/icons/Edit';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideoCallIcon from '@material-ui/icons/VideoCall';

const __superRole = 'superadmin';
const roles = [
    {code: __superRole, name: __superRole},
    {code: 'admin', name: 'admin'},
    {code: 'user', name: 'user'},
    {code: 'till', name: 'till'},
];

export const IPEYE_API = {
    URL: 'http://171.25.232.2:8111',
    methods: {
        getMp4Link: (camID, start, length) => `/device/nvr/file/mp4/${camID}/${start}/${length}`,
        getVideoPosterLink: (camID, name) => `/device/jpeg/cache/${camID}/${name}`,
    },
};

const admins = roles.filter(item => item.code !== 'till' && item.code !== 'user');

export const Config = {
    DEFAULT_STRUCTURE_ID: '1',
    API_URL: process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5000/' : process.env.TEST_ENV ? 'http://event-db-test-api.crm4retail.ru/' : 'https://event-db-api.crm4retail.ru/',
    jsonrpc: '2.0',
    responseTimeout: 10000,
    lang: 'RU',
    routes: {
        main: {
            path: '/main',
            name: 'Главная',
            icon: <HomeIcon/>,
            show: false,
            roles
        },
        eventLog: {
            path: '/main/event-log',
            name: 'Журнал событий',
            icon: <ArchiveIcon/>,
            show: true,
            roles
        },
        users: {
            path: '/main/users',
            name: 'Пользователи',
            icon: <AccountCircleIcon/>,
            show: true,
            roles: admins,
        },
        user: {
            path: '/main/user',
            name: 'Пользователь',
            icon: <AccountCircleIcon/>,
            show: false,
            roles: admins,
        },
        newUser: {
            path: '/main/users/new',
            pathname: '/main/users',
            name: 'Пользователи',
            icon: <AccountCircleIcon/>,
            show: false,
            roles: admins,
            child: { name: 'Новый', icon: <PersonAddIcon/> }
        },
        divisions: {
            path: '/main/divisions',
            name: 'Подразделения',
            icon: <TableChartIcon/>,
            show: true,
            roles: admins,
        },
        mediarecorders: {
            path: 'mediarecorders',
            pathName: 'mediarecorders',
            name: 'Записывающие устройства по подразделению',
            icon: <VideocamIcon/>,
            show: false,
            roles: admins,
        },
        mediarecordersList: {
            path: '/main/mediarecorders',
            name: 'Записывающие устройства',
            icon: <VideocamIcon/>,
            show: true,
            roles: admins,
        },
        newMediarecorder: {
            path: '#',
            pathName: '/main/mediarecorder',
            name: 'Записывающее устройство',
            icon: <VideocamIcon/>,
            show: false,
            roles: admins,
            child: { name: 'Новое', icon: <VideoCallIcon/> }
        },
        mediarecorder: {
            path: '#',
            pathName: '/main/mediarecorder',
            name: 'Записывающее устройство',
            icon: <VideocamIcon/>,
            show: false,
            roles: admins,
            child: { name: 'Редактирование', icon: <EditIcon/> }
        },
        workstations: {
            path: 'workstations',
            pathName: 'workstations',
            name: 'Рабочие места по подразделению',
            icon: <DesktopMacIcon/>,
            show: false,
            roles: admins,
        },
        workstationsList: {
            path: '/main/workstations',
            name: 'Рабочие места',
            icon: <DesktopMacIcon/>,
            show: true,
            roles: admins,
        },
        newWorkstation: {
            path: '#',
            pathName: '/main/workstation',
            name: 'Рабочее место',
            icon: <DesktopMacIcon/>,
            show: false,
            roles: admins,
            child: { name: 'Новое', icon: <AddToQueueIcon/> }
        },
        workstation: {
            path: '#',
            pathName: '/main/workstation',
            name: 'Рабочее место',
            icon: <DesktopMacIcon/>,
            show: false,
            roles: admins,
            child: { name: 'Редактирование', icon: <EditIcon/> }
        },
        clients: {
            path: '/main/clients',
            name: 'Клиенты',
            icon: <AccountCircleIcon/>,
            show: true,
            roles: [roles[0]],
        },
        client: {
            path: '/main/client',
            name: 'Данные клиента',
            icon: <AccountCircleIcon/>,
            show: false,
            roles: [roles[0]],
        },
        newClient: {
            path: '/main/clients/new',
            pathname: '/main/clients',
            name: 'Клиенты',
            icon: <AccountCircleIcon/>,
            show: false,
            roles: [roles[0]],
            child: { name: 'Новый', icon: <PersonAddIcon/> },
        },
        notFound: {
            path: '',
            name: 'Not Found',
            icon: <BlockRoundedIcon/>,
            show: false,
            roles
        },
        archive: {
            path: '/main/archive',
            name: 'Архив',
            icon: <ArchiveIcon/>,
            show: false,
            roles
        },
        stream: {
            path: '/main/stream',
            name: 'Прямая трансляция',
            icon: <LiveTvIcon/>,
            show: false,
            roles
        },
    },
    roles,
    __superRole,
    length: 1.5 * 60,// + 1.5 минут к началу события и его окончанию
}

export const Resources = {
    RU: {
        TABLE: {
            COLUMNS: {
                id: 'ID',
                author_id: 'ID автора',
                login: 'Логин',
                role: 'Роль',
                date: 'Дата',
                client_id: 'ID клиента',
                name: 'Название',
                dafault_name: 'Название по умолчанию',
                create_date: 'Дата создания',
                update_date: 'Дата изменения',
                data: 'Данные',
                action: 'Действия',
                workstation_id: 'Рабочее место',
                employer: 'Сотрудник',
                eventtype: 'Тип события',
                create_date_unix: 'Дата создания',
                bd_name: 'Название БД',
                select: 'Выбор',
                division_id: 'Подразделение',
                division_name: 'Название подразделения',
                mediarecord_id: 'Записывающее устройство',
                till_auth: 'Токен авторизации устройства',
                externalid: 'Внешний ID устройства',
                archive: ' ',
            }
        },
        STRUCTURE: {
            name: "Структура подразделений",
        }
    },
    EN: {
        TABLE: {
            COLUMNS: {
                id: 'ID',
                client_id: 'Client ID',
                name: 'Name',
                dafault_name: 'Default name',
                create_date: 'Create date',
                update_date: 'Update Date',
                data: 'Data',
                action: 'Actions',
            }
        }
    }
}
