import React from 'react';
import CheckAuth from './Auth.service';

import {
  Route,
  Redirect,
} from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      CheckAuth.isAuthenticated()
        ? <Component {...props} />
        : <Redirect to={{
            pathname: '/auth',
            state: { from: props.location }
          }} />
    )} />
)

export default PrivateRoute;