export const setCookie = function (key, value, options = {}) {
    options = { path: '/', ...options };
    
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
    
    let updatedCookie = encodeURIComponent(key) + "=" + encodeURIComponent(value);
    
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
    
    document.cookie = updatedCookie;
}

export const getCookie = function (key) {
    let matches = document.cookie.match(
        // eslint-disable-next-line no-useless-escape
        new RegExp("(?:^|; )" + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)")
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const deleteCookie = function(key) {
    setCookie(key, "", {
      'max-age': -1
    })
}
