import React, { Suspense, lazy } from 'react';

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import PrivateRoute from './components/Auth/PrivateRoute';

import './styles/mixins.scss';

const Main = lazy(() => import('./components/Main/Main'));
const Auth = lazy(() => import('./components/Auth/Auth'));

const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: 'jss-insertion-point',
});

class App extends React.Component {
  render() {
    return (
      <StylesProvider jss={jss}>
        <Router>
          <Suspense fallback={
              <div className="Loader">
                <CircularProgress />
              </div>
            }>
            <Switch>
              <Route exact path='/auth' component={Auth}/>
              <PrivateRoute path='/main' component={Main} />
              <Redirect from='/' to='/main'/>
            </Switch>
          </Suspense>
        </Router>
      </StylesProvider>
    )
  }
}

export default App;
