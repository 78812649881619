import { setCookie, getCookie, deleteCookie } from '../../services/CookieService';
import JsonRpc from '../../services/JsonRpcService';

const CheckAuth = {
    isAuthenticated: () => !!getCookie('token'),
    authenticate: async ({login, password}, cb) => {
        const res = { isSuccess: true };
        const result = await JsonRpc({
            method: 'Auth', 
            params: {login, password}, 
        }, null, true);
        if (result.errorCode === 0) {
            setCookie('token', result.token);
            setCookie('userId', result.idSystemUser);
            setCookie('role', result.role);
        } else {
            res.isSuccess = false;
            res.errorText = `${result.errorCode}: ${result.errorDescription}`;
        }
        cb(res);
    },
    removeAuthentificationData: () => {
        deleteCookie('token');
        deleteCookie('userId');
        deleteCookie('role');
    }
}

export default CheckAuth;